import React from "react";
import styled from "styled-components";
import HoldingLayout from "../components/holdingLayout";
import SEO from "../components/seo";

const IndexPage = () => (
  <HoldingLayout>
    <SEO title="Home" />
    <Content>
      <Logo xmlns="http://www.w3.org/2000/svg" viewBox="0 0 829.6 342.8">
        <path
          fill="#0B5526"
          d="M472.8 200.4V61.6h27.7v138.8h-27.7zM517.4 61.6h31.3l28 70.8L603.8 63h1.4l27.2 69.5 28-70.8h31.2l-56.7 141.1h-5l-25.5-61.5-25.2 62h-4.9L517.4 61.7zM749.8 200.4h-41.3V61.6h41.3c92.4 0 92.4 138.8 0 138.8zm-13.6-111v83.2h13.6c54 0 54-83.3 0-83.3h-13.6z"
        />
        <path
          fill="#351A5B"
          d="M472.8 220.4h22.6c10.2 0 16.5 7.3 16.3 17.4-.2 4.3-1.7 8.8-5.3 11.3 3.8 2.5 6 7 6 11.4a17 17 0 01-17 17.2h-22.6v-57.3zm11.5 23h10.2c3.3 0 5.3-2.6 5.3-5.8 0-3.2-2-5.7-5.3-5.7h-10.2v11.5zm0 22.9h10.2c3.3 0 5.7-2.6 5.7-5.8 0-3.2-2.4-5.6-5.7-5.6h-10.2v11.4zM539.5 218.4h1.2l24 59.3H552l-2.3-6.1h-19l-2.2 6.1h-13l24-59.3zm5.8 42.5l-5.2-15-5.3 15h10.5zM582.2 277.7h-11.4v-58.4h3l29 33.6v-32.3h11.4V279h-3l-29-32.4v31.1zM625.1 220.4h22.7c10.2 0 16.5 7.3 16.3 17.4-.2 4.3-1.7 8.8-5.3 11.3 3.7 2.5 5.9 7 5.9 11.4a17 17 0 01-16.9 17.2h-22.7v-57.3zm11.6 23h10.1c3.3 0 5.3-2.6 5.3-5.8 0-3.2-2-5.7-5.3-5.7h-10.1v11.5zm0 22.9h10.1c3.3 0 5.8-2.6 5.8-5.8 0-3.2-2.5-5.6-5.8-5.6h-10.1v11.4zM672.5 257.8v-37.3H684v37.2c0 12 18.6 12 18.6 0v-37.2h11.3v37.3c0 27.2-41.4 27.2-41.4 0zM741.5 255.9H736v21.8h-11.4v-57.2h22.6c20.4 0 22.4 28.7 5.6 33.6l13.3 23.7h-13l-11.6-22zm4.6-11.1c7.8 0 7.8-12.9 0-12.9h-10v12.9h10zM785.7 252l-20-31.5h14l11.6 19.7 11.7-19.7h14L797 252v25.7h-11.4V252z"
        />
        <g fill="#042346">
          <path d="M186.8 175l4.8 1.6s7-.5 9.8.9c3 1.4 4.2 3.6 5 4.5.9.8-1.3 2.5-2 4-.7 1.3-.9 2.2-2.3 3.2-1.3 1-1.5 1.5-2.7 3-1.2 1.3-2.6 3.2-5.2 2.2-2.5-1-7.5-5.9-9-6.2-1.3-.4-6.1 1-6.1 1s0-5.5 1.2-7.8c1.2-2.2 6.5-6.3 6.5-6.3zm30.1 37v2.3s-3.8 1-5.6 3.1a260.4 260.4 0 00-11.6 35.3c-1.5 7-7.8 34.8-21.7 47.5-13.9 12.6-28 5.3-28 5.3l5.9-8.2s3.9-.3 6.5-.8c2.7-.5 7.6-3.1 11-6.3 3.4-3.2 9.2-26 10.5-31.9 1.2-5.8 13.6-37 17.2-40.6 3.7-3.7 15.8-5.6 15.8-5.6zm64.5 23s4.2 7.3 5.4 10.7c.9 2.4 2.1 7.6 2.7 11.6-3.1.8-6 1.3-6.7 1l-3-1.4 1.6-22z" />
          <path d="M221.8 120c1.6.4 5 1.5 5.6 2 2.7 2.5 7.6 27.8 7.6 32a191 191 0 002.6 18.4l4.4-2s2.5-6 4.9-9.4c2.4-3.4 6.6-3.2 6.6-3.2l5.8-6.5c2.4-2.7 8.8-1.5 8.8-1.5s1.2-3 5.5-4.9c4.4-2 7.8 1.2 7.8 1.2s1.7-1.9 3.4-2.9 9 2 9 2l4.4-5.6c1.2-1.7 4.2-2.5 4.4-1l1.2 13.1h8.3c3.6 0 8.8 5.2 8.8 5.2s18.2-13.2 17.7-14.9c-.5-1.7 2-3.2 4-2.4 1.8.7-4.2 9.5-5.2 13.8-1 4.4-10 16.8-10 16.8l2.2 19.5c.2 2.4 3.9 6 3.9 8.8 0 2.6-5.8 12.4-8.5 18a86.4 86.4 0 00-4 26c.3 4.1 1.3 17.8 3.7 23.4 2.5 5.6-2.4 7.8-3.9 12.1-1.4 4.4-8.2 6.6-14 6.4-5.9-.3-7.9-5.6-7.9-5.6l-1.9-1c-2-1-3.9-4.9-5.4-6.8-.9-1.3-1.3-3.6-1.5-6-3.9.7-8.5.8-10.9.8a83 83 0 005.4 28.7c32.7 60.3 88.5 56.7 113.4 29.6C460.6 244.8 308.8-.8 206.4 0 104.1.8-22.9 180.3 3.5 284.3c21.7 66.3 48.2 42.4 48.2 42.4 4.2-4.1 6.1-9.2 6.1-9.2l-4.6-.3s2-1 6.5-5C64.4 308 70 292.8 70 292.8s.2-6.3 1-14.8c.7-8.6 3.1-21.7 28.2-42.4a54.7 54.7 0 0147-11.6c-1.7 4.8-8.7 14-9.8 15-1.5 1.5-12.2 8.5-13.1 9.5-1 1-7.6 3.7-6.6 6.3 1 2.7 5.9 6.7 5.9 6.7s-11 21.3-9 30c1.9 8.8 13 11.3 13 11.3l1.8-3.7h-.3c1-2 2.2-4.5 2.5-6a721 721 0 0124.3-39.5l-.5-3.4-19.2 15c-2-1.2-14-9.1-13.2-11.4 1.7-2.6 14-9.7 17-11.9 12.8-10 13.2-36.4 27.5-46 0 0-16-6.6-17.7-9.4a27.4 27.4 0 01-3.1-12.2c.3-2.6 1.2-20.3 2.2-22.7 1-2.4 2.4-25.1 2.8-26.7.3-1.5 21-8 21.8-9 .9-.8 5-5.3 5-5.3s.6-.8 1.2-2.6c.7-1.7 1.4-5 1.4-5s-4.5-2.7-5-4.6c-.5-1.9-.8-4.6-.8-4.6s-3.3-1.4-4.3-2.4-2.8-4.2-2.5-6.4c.4-2.3 5.4-8.4 5.4-8.4l-.4-5.2c0-1.2 3.1-4.8 14.5-8a21 21 0 0119.1 3.4l-.4 7.5s3.3-1 6 2.4c2.8 3.5-5.8 11-5.8 11V90c0 1-2.2 8.4-2.7 12.7-.6 4.3-4 4.5-4 4.5v.9c0 .6 2.8 6.7 2.8 6.7s1.4 1.3 3.5 2.1l.8.3c-7.3 13.5-17 28.4-28.2 44.3-1-7.1-4.5-17.8-4.5-17.8s-3.5 18.4-2.1 19.6c1.4 1.2 5.5 5.6 7 4.3.4-.2 0-3.3 0-3.3a337.2 337.2 0 0030-46.6l.8.1c3 .5 6.2.9 10.2 1.7-25.6 41.9-36.3 53.3-36.3 53.3l2.4.8s6.8-6.2 36.2-53.4z" />
        </g>
      </Logo>
      <h1>International Women&apos;s Day Banbury</h1>
      <Lede>March 2020</Lede>
      <p>Check back soon for more details</p>
    </Content>
  </HoldingLayout>
);

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  text-align: center;
`;

const Logo = styled.svg`
  display: block;
  max-width: 30rem;
`;

const Lede = styled.p`
  font-size: 1.3em;
`;

export default IndexPage;
